<template>
    <div class='calendar'>
  
      <!-- edit icons -->
      <div v-if="loggedIn" class="ma-2">
        <v-row>
          <v-btn outlined elevation="0" color="success" @click="savePage"> Save
            <v-icon right>mdi-content-save-outline</v-icon>
          </v-btn>
          <v-btn outlined elevation="0" 
            color="warning" class="mx-1" 
            @click="previewPage"
            v-if="preview != true">Preview
            <v-icon right>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn outlined elevation="0" 
            color="warning" class="mx-1" 
            @click="previewPage"
            v-else>Edit
            <v-icon right>mdi-pencil</v-icon>
          </v-btn>
          <div class="mx-4 text-h5 grey--text">{{ convertDate() }}</div>
        </v-row>
      </div>
  
      <!-- content -->
      <div v-if="loggedIn===true && preview == false">
        <v-textarea full-width rows=20 v-model="contentData"></v-textarea>
      </div>
      <div class="my-4" v-if="loggedIn!==true || preview" >
        <markdown-it-vue-light refs="markIt" :content="contentData" :options="options"/>
      </div>
    </div>
  </template>
  
<script>
  import MarkdownItVueLight from 'markdown-it-vue/dist/markdown-it-vue-light.umd.min.js'
  import 'markdown-it-vue/dist/markdown-it-vue-light.css'
  import { eventBus } from "../main";
  
  export default {
    components: {
      MarkdownItVueLight
    },
    data() {
      return {
        options:{
          
        },
        contentData: "",
        caleId:NaN,
        loggedIn: false,
        preview:false,
        isCreated: false
      }
    },
    methods:{
      loadPage(){
        if (this.caleId){
          const requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.$token.value
            },
          }
          var url = this.$webUrl+"/api/cal/"+this.caleId
          fetch(url, requestOptions).then(async response => {
              const data = await response.json();
              if (response.ok) {               
              //success            
              this.contentData = data.content
              this.isCreated = true
              }
              else {
                this.isCreated = false
                this.contentData = "### Todos\n"
              }
            })
        }
        else{
          this.contentData = "# Date stuff goes here\n"
        }
      },
      previewPage(){
        this.preview = !this.preview
      },
      savePage(){
        if(this.isCreated){
          const requestOptions = {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.$token.value
            },
            body: JSON.stringify({
              id: parseInt(this.caleId),
              content: this.contentData
            })
          }
          var url = this.$webUrl+"/api/cal/save"
          fetch(url, requestOptions)
            .then(async response => {
              const data = await response.json();
              if (!response.ok) {
                // error
                const error = (data && data.message) || response.statusText;              
                return Promise.reject(error);
              }
              //success
              this.$root.toast.show({message: 'Day saved. 👍', color: 'success'})
            })
            .catch(error => {
              this.$root.toast.show({message: 'Error saving 😱', color: 'warning'})
              console.error('[ERROR]', error)
            });
        }
        else {
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.$token.value
            },
            body: JSON.stringify({
              id: parseInt(this.caleId),
              content: this.contentData
            })
          }
          var url2 = this.$webUrl+"/api/cal"
          fetch(url2, requestOptions)
            .then(async response => {
              const data = await response.json();
              if (!response.ok) {
                // error
                const error = (data && data.message) || response.statusText;              
                return Promise.reject(error);
              }
              //success
              this.isCreated = true  
              this.$root.toast.show({message: 'Day saved. 👍', color: 'success'})
            })
            .catch(error => {
              this.$root.toast.show({message: 'Error saving 😱', color: 'warning'})
              console.error('[ERROR]', error)
            });
        }
      },
      tokenHandler(){
        if (this.$token.value == ''){
          this.loggedIn = false
          this.preview = false
        } else {
          this.loggedIn = true
        }
      },
      convertDate(){
        var k = this.caleId
        var yr = k.slice(0,4)
        var mon = k.slice(4, 6)
        var day = k.slice(6, 8)
        var mydate = new Date(yr, mon-1, day)
        return mydate.toDateString()
      }
    },
    watch: {
      $route (){
        this.caleId = this.$route.params.id
        this.loadPage()   
      }
    },
    created() {
      this.caleId = this.$route.params.id
      this.loadPage()
      this.tokenHandler()
      eventBus.$on('gotToken', this.tokenHandler);
    },
    beforeDestroy(){
      eventBus.$off('gotToken', this.tokenHandler)
    }
  }
</script>
  
<style>
  .theme--dark .markdown-body {
    color: white !important;    
  }
  .theme--dark .markdown-body table * {
    background: rgb(18, 18, 18);
  }
  .theme--dark .markdown-body pre {
    background-color:  rgb(50, 50, 50);
    color:  rgb(255,255,255);
  }
  .theme--dark .markdown-body pre *{
    background-color:  rgb(50, 50, 50);
  }
  .theme--light .markdown-body pre {
    background-color:  rgb(235, 235, 235);
  }
  .theme--light .markdown-body pre *{
    background-color: rgb(235, 235, 235);
  }
  .markdown-body{
    font-family: monospace;
  }
  .v-textarea textarea {
    font-family: monospace;
  }
</style>
